<script lang="ts">
  import { onMount } from "svelte";
  import { getNamedEntities } from "../services/cleanup";
  import Loading from "./Loading.svelte";
  import NamedRow from "./NamedRow.svelte";
  import NameFilter from "./NameFilter.svelte";
  export let projectId;
  let nameData;
  let filteredData;
  let currentFilter = "";
  let dangerEnabled = false;
  $: {
    if (nameData) {
      console.log("Name data is", nameData);
      filteredData = nameData.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(currentFilter.toLowerCase()) > -1
        );
      });
    }
  }

  const loadData = async () => {
    nameData = await getNamedEntities(projectId, "person");
  };
  onMount(async () => {
    await loadData();
  });
</script>

<div class="panel" class:show-danger={dangerEnabled}>
  {#if nameData && nameData}
    <NameFilter bind:value={currentFilter} />
    <table class="tight-input">
      <thead>
        <th width="50%">Current entry</th>
        <th width="50%">New entry</th>
        <th class="is-narrow" />
        <th
          class="is-danger"
          on:click={() => {
            dangerEnabled = !dangerEnabled;
          }}>Danger zone</th
        >
      </thead>
      <tbody>
        {#each filteredData as c, index (c.id)}
          <NamedRow {projectId} data={c} removed={loadData} />
        {/each}
      </tbody>
    </table>
  {:else}
    <Loading />
  {/if}
</div>

<style lang="scss">
  button {
    line-height: 18px;
    font-weight: normal;
  }
  th.is-danger {
    width: 160px;
  }
</style>
