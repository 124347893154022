<script lang="ts">
  import { onMount } from "svelte";
  import CreateProject from "../components/CreateProject.svelte";
  import Row from "../components/Row.svelte";
  import { getProjects } from "../services/project";
  import { pageTitle, isAdmin, isSuperAdmin } from "../stores/store";
  import { portal } from "svelte-portal/src/Portal.svelte";
  import ProjectSelect from "../components/ProjectSelect.svelte";
  import Loading from "../components/Loading.svelte";

  let projects;
  let projectStore;
  $: projects = $projectStore || [];

  $pageTitle = "Projects";

  const projectCreated = (e) => {
    projects.push(e.detail.project);
    $projectStore = projects;
  };

  onMount(async () => {
    projectStore = await getProjects();
  });
</script>

{#if !projectStore}
  <Loading />
{:else}
  <div use:portal={".is-target"} />
  {#if $isAdmin}
    <CreateProject on:created={projectCreated} />
  {/if}
  {#if projects.length > 0}
    <!-- <select>
      {#each projects as { name, slug }, index (index)}
        <option>{name}</option>
      {/each}
    </select> -->
    <div class="panel">
      <table class="tight">
        <thead>
          <th width="50%">Community name</th>
          <th class="is-narrow">Workshop date</th>
          <th class="is-narrow">Encryption date</th>
          <th class="is-narrow">Cleanup date</th>
          {#if $isAdmin}
            <th width="50%" colspan="2">Notes</th>
          {:else}
            <th />
          {/if}
          <!-- <th class="is-plain3" width="1" /> -->
        </thead>
        <tbody>
          {#each projects as p, index (index)}
            <Row bind:p={projects[index]} />
          {/each}
        </tbody>
      </table>
    </div>
  {:else}
    <h2>No projects exist</h2>
  {/if}
{/if}
