<script lang="ts">
  import { onMount } from "svelte";
  import { dataset_dev } from "svelte/internal";
  import { getDataForCleanup, onlyEditable } from "../services/cleanup";
  import { getNameQuestionIndexes, getQuestions } from "../services/project";
  import { readable_question } from "../utils/readable_question";
  import Loading from "./Loading.svelte";
  import QuestionRow from "./QuestionRow.svelte";
  export let projectId;
  let cleanupData;
  let filteredData;
  let questions;
  let dangerEnabled = false;
  let doneDelete = false;
  const loadData = async () => {
    questions = await getNameQuestionIndexes(projectId);
    cleanupData = await getDataForCleanup(projectId);
    filteredData = await onlyEditable(cleanupData, questions);
  };
  const updated = () => {
    loadData();
  };
  onMount(async () => {
    await loadData();
  });
</script>

<div class="panel" class:show-danger={dangerEnabled}>
  {#if filteredData}
    <table class="tight-input">
      <thead>
        <th class="is-narrow" />
        <th width="50%">Current</th>
        <th width="50%">New entry</th>
        <th class="is-narrow" />
        <th
          class="is-danger"
          colspan="2"
          on:click={() => {
            dangerEnabled = !dangerEnabled;
          }}>Danger zone</th
        >
      </thead>
      <tbody>
        {#each filteredData as c, index (index)}
          {#each c.data as item, innerIndex}
            <QuestionRow
              rowsInBlock={c.data.length}
              firstRowInBlock={innerIndex === 0}
              finalRowInBlock={innerIndex + 1 >= c.data.length}
              {item}
              user={c.user}
              {projectId}
              {updated}
            />

            <!-- {/if} -->
          {/each}
        {/each}
      </tbody>
    </table>
  {:else}
    <Loading />
  {/if}
</div>

<style lang="scss">
</style>
