import { useAuth0 } from "../services/auth0";
let { getAccessToken, user } = useAuth0;

const base = import.meta.env.VITE_API_SERVER_URL;

interface SendArguments {
  method: string;
  path: string;
  data?: Record<string, any>;
}

interface FetchOptions {
  method: string;
  headers: Record<string, any>;
  body?: string;
}

async function send({ method, path, data }: SendArguments) {
  const opts: FetchOptions = { method, headers: {} };
  const token = await getAccessToken();
  opts.headers["Authorization"] = `Bearer ${token}`;
  if (data) {
    opts.headers["Content-Type"] = "application/json";
    opts.body = JSON.stringify(data);
  }

  const response = await fetch(`${base}${path}`, opts);
  const json = await response.text();
  var resParsed;
  try {
    resParsed = JSON.parse(json);
  } catch (e) {
    resParsed = {};
    resParsed.message = "";
    resParsed.errors = [];
  }
  if (response.status > 299) {
    return {
      code: response.status,
      message: resParsed.message,
      errors: resParsed.errors,
    };
  }
  try {
    return resParsed;
  } catch (err) {
    console.log("Error");
    return json;
  }
}

export function get(path) {
  return send({ method: "GET", path });
}

export function del(path) {
  return send({ method: "DELETE", path });
}

export function post(path, data) {
  return send({ method: "POST", path, data });
}

export function put(path, data) {
  return send({ method: "PUT", path, data });
}

export function patch(path, data) {
  return send({ method: "PATCH", path, data });
}
