import { del, get, patch, put } from "./api";

export const getDataForCleanup = async (id) => {
  const url = `/projects/${id}/data`;
  const data = await get(url);
  return data;
};

export const getNamedEntities = async (id: string, type = "org") => {
  let url = `/projects/${id}/names`;
  if (type != "org") {
    url = `${url}?type=${type}`;
  }
  const data = await get(url);
  // console.log("Returned data", data);
  return data;
};

export const updateName = async (
  projectId: string,
  nameId: string,
  value: string
) => {
  nameId = encodeURIComponent(nameId);
  let url = `/projects/${projectId}/names/${nameId}`;
  const result = await put(url, { name: value });

  return result;
};

export const updateAnswerForUser = async (
  projectId: string,
  user: string,
  answers: string[],
  identifier: string
) => {
  let url = `/projects/${projectId}/data/${user}`;
  const data = { identifier, answers };
  const result = await patch(url, data);
  return result;
};

export const deleteUserData = async (projectId: string, user: string) => {
  let url = `/projects/${projectId}/data/${user}`;
  const data = await del(url);
  return data;
};

export const deleteName = async (projectId: string, nameId: string) => {
  nameId = encodeURIComponent(nameId);
  let url = `/projects/${projectId}/names/${nameId}`;
  const data = await del(url);
  return data;
};

export const onlyEditable = async (data, questionIdentifiers) => {
  // this ugly looking beast is mangling the cleanup data into an easy format to work with
  // the result will be an array of users, with each user containing an array of
  // editable answers
  return data.map((userData) => {
    return {
      ...userData, // include all other props
      data: userData.data
        .filter((answers) => {
          return questionIdentifiers.indexOf(answers.identifier) !== -1; // omit any answers to questions that we can't edit
        })
        .reduce((acc, curr) => {
          return [
            ...acc,
            ...curr.answers
              .filter((a) => a != "")
              .map((a, index) => {
                return {
                  identifier: curr.identifier,
                  answer: a,
                  allAnswers: curr.answers,
                  indexForQuestion: index,
                };
              }),
          ];
        }, []),
    };
  });
  return data;
};
