<script lang="ts">
  import { deleteName, updateName } from "../services/cleanup";

  export let data;
  export let projectId;
  export let removed = null;

  let replacement = "";

  const update = async () => {
    const result = await updateName(projectId, data.id, replacement);
    if (result && result.success) {
      data.name = replacement;
      replacement = "";
    }
  };

  const del = async () => {
    const result = await deleteName(projectId, data.id);
    if (result && result.success) {
      if (removed) {
        removed();
      }
    }
  };
</script>

<tr class="is-tight">
  <td>
    {data.name}
  </td>
  <td>
    <input bind:value={replacement} type="text" />
  </td>
  <td class="remove-padding"
    ><button class="btn" on:click={update}>global update</button></td
  >
  <td class="is-danger"
    ><button class="btn" on:click={del}>remove name</button></td
  >
</tr>

<style lang="scss">
  button {
    line-height: 18px;
    font-weight: normal;
  }
  th.is-danger {
    width: 160px;
  }
</style>
