<script lang="ts">
  import ProjectTabs from "../components/ProjectTabs.svelte";
  import { pageTitle, isAdmin, isSuperAdmin } from "../stores/store";
  import { onMount } from "svelte";
  import EditableDate from "../components/EditableDate.svelte";
  import {
    hasKeys,
    deletePublicKey,
    restorePublicKey,
  } from "../services/encryption";
  import page from "page";
  import Loading from "../components/Loading.svelte";
  import {
    deleteProject,
    getProject,
    updateProject,
  } from "../services/project";
  import { text_area_resize } from "../utils/autoresize_textarea";

  export let projectId;
  let project: Record<string, any> | undefined;
  let url;
  $: url = import.meta.env.VITE_SITE_PREFIX + project?.slug;
  let errors: Record<string, any> = {};

  let hasPublicKey = true;
  let loadedKeys = false;

  const handleChange = async () => {
    const result = await updateProject(project);
    errors = result.errors || [];
    console.log("Errors", errors);
  };

  const handleDelete = async () => {
    if (confirm("Really?")) {
      const result = await deleteProject(project);
      page.redirect("/projects");
    }
  };

  const deleteKey = async () => {
    const result = await deletePublicKey(projectId);
    if (result && result.success) {
      hasPublicKey = false;
    }
  };

  const restoreKey = async () => {
    const result = await restorePublicKey(projectId);
    if (result && result.success) {
      hasPublicKey = true;
    }
  };

  const getQuestionOptions = () => {
    return project.questionSet.questions.reduce(
      (acc, question) => {
        console.log("Acc", acc, question);
        return [
          ...acc,
          {
            value: acc.length - 1,
            label: question.label,
          },
        ];
      },
      [{ value: -1, label: "No gating" }]
    );
  };

  onMount(async () => {
    project = await getProject(projectId);
    if (project.errors) {
      page.redirect("/projects");
    }
    console.log("Project is", project);
    $pageTitle = project.name;

    if ($isAdmin) {
      try {
        const result = await hasKeys(projectId);
        hasPublicKey = result.hasKeys;
        loadedKeys = true;
      } catch (e) {}
    }
  });
</script>

<ProjectTabs {projectId} />

{#if project}
  <div class="panel form-styled">
    <div class="grid">
      <div class="cell">
        <div class="grid">
          <div class="cell cell--narrow text-right label">Name</div>
          <div class="cell">
            <input
              bind:value={project.name}
              on:blur={handleChange}
              type="text"
            />
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Slug</div>
          <div class="cell">
            <input
              bind:value={project.slug}
              on:blur={handleChange}
              type="text"
            />
            <a class="sitelink" href={url} target="_blank">{url}</a>
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Workshop date</div>
          <div class="cell">
            <div class="constrain">
              <EditableDate
                bind:date={project.workshopDate}
                change={handleChange}
              />
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Encryption date</div>
          <div class="cell">
            <div class="constrain">
              <EditableDate
                bind:date={project.encryptionDate}
                change={handleChange}
              />
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Clean up date</div>
          <div class="cell">
            <div class="constrain">
              <EditableDate
                bind:date={project.cleanupDate}
                change={handleChange}
              />
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Notes</div>
          <div class="cell">
            <textarea
              bind:value={project.notes}
              on:blur={handleChange}
              use:text_area_resize
            />
          </div>
        </div>
        <div class="grid">
          <div class="cell cell--narrow text-right label">Question gating</div>
          <div class="cell">
            <select bind:value={project.questionNumber} on:blur={handleChange}>
              {#each getQuestionOptions() as question}
                <option value={question.value}>{question.label}</option>
              {/each}
            </select>
          </div>
        </div>
      </div>
      <div class="cell">
        <a href={url} class="btn" target="_blank">View questions</a>
        <a href={url + "/network"} class="btn" target="_blank">View network</a>

        <button class="btn is-danger" on:click={handleDelete}
          >Delete community</button
        >

        {#if $isAdmin && loadedKeys}
          <br /><br />
          {#if hasPublicKey}
            <button class="btn is-danger" on:click={deleteKey}
              >Encrypt public data</button
            >
          {:else}
            <button class="btn is-danger" on:click={restoreKey}
              >Decrypt public data</button
            >
          {/if}
        {/if}
      </div>
    </div>
  </div>
{:else}
  <Loading />
{/if}

<style lang="scss">
  .grid {
    margin-bottom: 1em;
  }
  .label {
    font-weight: bold;
    line-height: 42px;
  }
  input,
  textarea,
  select,
  .constrain {
    max-width: 300px;
  }

  a.sitelink {
    display: block;
    margin-top: 1em;
    font-weight: bold;
    font-size: 0.75em;
    color: inherit;

    &:focus {
      outline: none;
    }
  }
</style>
