import { get, post } from "./api";
import { getNamedEntities } from "./cleanup";

export const getLinks = async (id: string) => {
  let nameData = await getNamedEntities(id);
  const url = `/projects/${id}/links?$limit=10000`;
  const data = await get(url);
  // reduce the list of links to a hash map
  const linkData = data.data.reduce(function (
    map: Record<string, any>,
    obj: any
  ) {
    map[obj.pseudonymId] = obj;
    return map;
  },
  {});
  // now return the list of names, with an additional prop representing the magic link if it exists..
  return nameData.map((name: any) => {
    return { ...name, link: linkData[name.id] };
  });
  return data;
};

export const createLink = async (projectId, identifier) => {
  const url = `/projects/${projectId}/links`;
  console.log("Creating link with id", identifier);
  const link = await post(url, { id: identifier });
  console.log("Link result", link);
  return link;
};
