<script lang="ts">
  import { onMount } from "svelte";

  import CleanupOrgs from "../components/CleanupOrgs.svelte";
  import CleanupPeople from "../components/CleanupPeople.svelte";
  import CleanupQuestions from "../components/CleanupQuestions.svelte";
  import Loading from "../components/Loading.svelte";
  import ProjectTabs from "../components/ProjectTabs.svelte";
  import Tabs from "../components/Tabs.svelte";
  import { getProject, updateProject } from "../services/project";

  import { pageTitle } from "../stores/store";

  // the Project ID from the URL
  export let projectId;
  let project;
  let field;
  let hasError = false;

  const save = async () => {
    hasError = false;
    try {
      const questionSet = JSON.parse(field.value);
      // if still here, it's valid JSON
      project.questionSet = questionSet;
      const result = await updateProject(project);
      console.log("Result", result);
    } catch (error) {
      hasError = true;
    }
  };
  onMount(async () => {
    project = await getProject(projectId);
    $pageTitle = project.name;
  });
</script>

<ProjectTabs {projectId} />
{#if project}
  <div class="panel">
    <textarea
      class="is-massive"
      class:has-error={hasError}
      bind:this={field}
      value={JSON.stringify(project.questionSet, undefined, 4)}
    />
    <div>
      <button class="btn" on:click={save}>Save</button>
    </div>
  </div>
{:else}
  <Loading />
{/if}

<style lang="scss">
  textarea {
    margin-bottom: 1em;
  }

  textarea.has-error {
    border: 1px solid red;
  }

  .panel div {
    display: flex;
  }
  button {
    margin-left: auto;
  }
</style>
