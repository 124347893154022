<script lang="ts">
  import { updateProject } from "../services/project";
  import Btn from "./Btn.svelte";
  import ConstrainHeight from "./ConstrainHeight.svelte";
  import { pageTitle, isAdmin, isSuperAdmin } from "../stores/store";
  import EditableDate from "./EditableDate.svelte";
  import EditableField from "./EditableField.svelte";

  export let p;
  let errors: Record<string, any> = {};

  const handleChange = async () => {
    const result = await updateProject(p);
    errors = result.errors || [];
    console.log("Errors", errors);
  };
</script>

<tr>
  <td
    ><EditableField
      bind:value={p.name}
      error={errors.name}
      change={handleChange}
      enabled={$isAdmin}
    />
  </td>
  <td>
    <EditableDate
      bind:date={p.workshopDate}
      change={handleChange}
      enabled={$isAdmin}
    />
  </td>
  <td>
    <EditableDate
      bind:date={p.encryptionDate}
      change={handleChange}
      enabled={$isAdmin}
    />
  </td>
  <td>
    <EditableDate
      bind:date={p.cleanupDate}
      change={handleChange}
      enabled={$isAdmin}
    />
  </td>
  {#if $isAdmin}
    <td>
      <ConstrainHeight>
        <EditableField
          bind:value={p.notes}
          change={handleChange}
          textArea={true}
        />
      </ConstrainHeight>
    </td>
  {/if}
  <td class="is-transparent is-narrow">
    {#if $isAdmin}
      <Btn href="/projects/{p._id}">View</Btn>
    {:else}
      <Btn href="/projects/{p._id}/participants">View</Btn>
    {/if}
  </td>
</tr>
