<script lang="ts">
  import { onMount } from "svelte";

  import CleanupOrgs from "../components/CleanupOrgs.svelte";
  import CleanupPeople from "../components/CleanupPeople.svelte";
  import CleanupQuestions from "../components/CleanupQuestions.svelte";
  import ProjectTabs from "../components/ProjectTabs.svelte";
  import Tabs from "../components/Tabs.svelte";
  import { getProject } from "../services/project";

  import { pageTitle } from "../stores/store";

  // the Project ID from the URL
  export let projectId;

  const tabs = [
    { label: "All Questions", component: CleanupQuestions },
    { label: "Search People", component: CleanupPeople },
    { label: "Search Orgs", component: CleanupOrgs },
  ];

  onMount(async () => {
    let project = await getProject(projectId);
    $pageTitle = project.name;
  });
</script>

<ProjectTabs {projectId} />
<div class="panel">
  <div class="panel-inner">
    <Tabs {tabs} {projectId} />
  </div>
</div>
