<script lang="ts">
  import { onMount } from "svelte";
  import { deleteAdmin, getAdmins, addAdmin } from "../services/admin";
  import Loading from "../components/Loading.svelte";
  import CreateFacilitator from "../components/CreateFacilitator.svelte";
  import { pageTitle } from "../stores/store";
  import CreateAdmin from "../components/CreateAdmin.svelte";
  let admins;
  onMount(async () => {
    admins = await getAdmins();

    $pageTitle = "Manage administrators";
  });
  const onAdded = async () => {
    admins = await getAdmins();
  };
  const delAdmin = async (user) => {
    const result = await deleteAdmin(user.email);
    if (result && result.success) {
      admins = admins.filter((f) => {
        return f.email != user.email;
      });
    }
  };
</script>

<div class="panel">
  <div class="grid">
    <div class="cell is-first">
      {#if admins}
        <table style="max-width:500px">
          <thead>
            <th>Email address</th>
            <th>Admin type</th>
            <th class="is-danger" width="160">Danger zone</th>
          </thead>
          <tbody>
            {#each admins as f, index (f._id)}
              <tr>
                <td>
                  {f.email}
                </td>
                <td>{f.adminRole}</td>
                <td class="is-danger">
                  <button
                    class="btn"
                    on:click={() => {
                      delAdmin(f);
                    }}>Delete</button
                  >
                </td>
              </tr>
            {/each}
            {#if admins.length === 0}
              <tr>
                <td colspan="2">No admins have been created</td>
              </tr>
            {/if}
          </tbody>
        </table>
      {:else}
        <Loading />
      {/if}
    </div>
    <div class="cell"><CreateAdmin on:created={onAdded} /></div>
  </div>
</div>

<style lang="scss">
  div.is-first {
    padding-right: 3rem;
  }
</style>
